@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  height: 100%;
  width: 100%;
  background-color: #0c0c0c;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0.1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.sidebar- {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: none;
}

.middle-content {
  width: 54vw;
}

.bottom-bar {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.middle-content-without-side-explore {
  padding: 25px;
  width: 89.5vw;
}

.end-content {
  width: 35.5vw;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: none;
}

.category-name-text {
  color: #ffffff;
  text-align: left;
  font: 700 28px "Plus Jakarta Sans";
  position: relative;
  margin-bottom: 16px;
}

.settings-icon {
  display: block;
}

@media (min-width: 375px) {
  .middle-content {
    width: 100%;
  }
  .middle-content-without-side-explore {
    padding: 25px;
    width: 100vw;
  }
}

@media (min-width: 768px) {
  .middle-content {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .settings-icon {
    display: none;
  }

  .sidebar- {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: inline;
  }

  .middle-content {
    width: 54vw;
    display: inline;
  }

  .end-content {
    width: 35.5vw;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: inline;
  }
}
@media (min-width: 1280px) {
  .sidebar- {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: inline;
  }

  .middle-content {
    width: 54vw;
    display: inline;
  }

  .end-content {
    width: 35.5vw;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: inline;
  }
}
