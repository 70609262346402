.search-in-collection,
.search-in-collection * {
  box-sizing: border-box;
}

.search-in-collection {
  border-radius: 10px;
  border: solid #2a2a2a;
  border-width: 1px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: #4d4d4d;
  background-color: transparent;
  text-align: left;
  font: 500 13px "Plus Jakarta Sans";
  letter-spacing: 0.5px;
  position: relative;
  margin: 0vh 3.4vw 0.6vh 3.4vw;
}

@media (min-width: 1024px) {
  .search-in-collection {
    margin: 0vh 1.3vw 0.6vh 1.3vw;
  }
}
