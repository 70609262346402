.base-dialog {
  background-color: rgb(19, 19, 19, 0.5);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  padding: 10px 20px 40px;
  display: flex;
  flex-direction: column;
}
