.content {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px 20px 40px;
	display: flex;
	flex-direction: column;
}

.auth-container,
.auth-container * {
	box-sizing: border-box;
}

.auth-container {
	display: flex;
	flex-direction: column;
	gap: 4.291845493562231vh;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.authheading {
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
}

.auth-title {
	color: #ffffff;
	text-align: center;
	font: 700 33.02px "Plus Jakarta Sans";
	position: relative;
	align-self: stretch;
	height: 39px;
}

.authinputs {
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	width: 276px;
	position: relative;
}

.email-input {
	border-radius: 10px;
	border: solid #202020;
	border-width: 1px;
	padding: 10px 10px 10px 10px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	background-color: transparent;
	height: 55px;
	color: #707070;
	text-align: left;
	font: 400 14.68px "Plus Jakarta Sans";
	position: relative;
}

.password-input {
	border-radius: 10px;
	border: solid #171717;
	border-width: 1px;
	padding: 10px 10px 10px 10px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	height: 55px;
	color: #707070;
	background-color: transparent;
	text-align: left;
	font: 400 14.68px "Plus Jakarta Sans";
	position: relative;
}

.option-text {
	text-align: left;
	font: 500 12.84px "Plus Jakarta Sans";
	position: relative;
}

.option-text-span {
	color: #868686;
	font: 500 12.84px "Plus Jakarta Sans";
}

.option-text-span2 {
	color: #1ca1f1;
	font: 500 12.84px "Plus Jakarta Sans";
	cursor: pointer;
}

.authbutton {
	background: #1ca1f1;
	border-radius: 12px;
	padding: 10px 10px 10px 10px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 332px;
	height: 59px;
	position: relative;
	cursor: pointer;
}

.authbutton-gray {
	background: #3a3a3a;
	border-radius: 12px;
	padding: 10px 10px 10px 10px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 332px;
	height: 59px;
	position: relative;
	cursor: pointer;
}

.auth-action-text {
	color: #ffffff;
	text-align: left;
	font: 700 19.92px "Plus Jakarta Sans";
	position: relative;
}
