input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
	-webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
	-webkit-text-fill-color: #333;
}

body::-webkit-scrollbar {
	width: 0.65em;
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: rgb(36, 36, 36);
	border-radius: 10px;
}
