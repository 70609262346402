html {
	height: 100%;
	width: 100%;
	background-color: #000000;
}
body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

.landing {
	width: 100%;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.landing::-webkit-scrollbar {
	width: 0.1em;
}

.landing::-webkit-scrollbar-track {
	box-shadow: inset 0 0;
}

.landing::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-radius: 10px;
}

.navbar,
.navbar * {
	box-sizing: border-box;
}

.navbar {
	background: rgba(0, 0, 0, 0.6);
	border: solid #2e2d2d;
	border-width: 0px 0px 1px 0px;
	height: 99.5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 999;
	backdrop-filter: blur(10px);
}

@media (min-width: 1024px) {
	.navbar {
		height: 13.024vh;
	}
	.logo-navbar {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.logo-navbar {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-content,
.hero-content * {
	box-sizing: border-box;
}

.hero-content {
	display: flex;
	flex-direction: column;
	gap: 26.12px;
	margin-top: 16.3vh;
	align-items: center;
	justify-content: flex-start;
	width: 860px;
	position: relative;
}

.hero-title-text-and-description {
	display: flex;
	flex-direction: column;
	gap: 13.93px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	width: 100%;
	position: relative;
}

.hero-title {
	color: #ffffff;
	text-align: center;
	font: 600 94.15px "Plus Jakarta Sans";
	position: relative;
	align-self: stretch;
}

.u-text-mark {
	position: relative;
	z-index: 1;
}

.u-text-mark:after {
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 0.3em;
	background-image: url("https://qrate.ap-south-1.linodeobjects.com/underline.svg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: bottom;
	left: 0;
	bottom: -0.1em;
	z-index: -1;
}
.hero-description {
	color: #555555;
	text-align: center;
	font: 400 17.59px/27.87px "Plus Jakarta Sans";
	position: relative;
	width: 80%;
	letter-spacing: 0.5px;
	padding: 8px 0px 0px 0px;
}

.hero-ctas {
	display: flex;
	flex-direction: row;
	gap: 13.06px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
}

.see-usecase-button {
	background: #0d0d0d;
	border-radius: 13.06px;
	padding: 8.71px 21.77px 8.71px 21.77px;
	display: flex;
	flex-direction: row;
	gap: 8.71px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	cursor: pointer;
	height: 55.73px;
	position: relative;
}

.see-usecases-button-text {
	color: #cecece;
	text-align: center;
	font: 600 15.2px "Plus Jakarta Sans";
	position: relative;
}

.get-started-button {
	background: #ffffff;
	border-radius: 13.06px;
	padding: 8.71px 21.77px 8.71px 21.77px;
	display: flex;
	flex-direction: row;
	gap: 8.71px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	cursor: pointer;
	position: relative;
}

.get-started-button-text {
	color: #000000;
	text-align: center;
	font: 700 17.22px "Plus Jakarta Sans";
	position: relative;
}

@media (min-width: 280px) {
	.hero-title-text-and-description {
		gap: 1.823vh;
	}

	.hero-content {
		width: 90%;
		padding: 2px;
	}

	.hero-title {
		font: 600 10.935vw "Plus Jakarta Sans";
	}

	.second-section-title {
		font: 600 5.935vw "Plus Jakarta Sans";
	}
	.hero-description {
		font: 400 3.533vw/6vw "Plus Jakarta Sans";
		width: 80%;
	}

	.get-started-button {
		padding: 4vw 6.418vw;
		height: 6vh;
	}
	.see-usecase-button {
		padding: 2vh 4.418vw;
	}

	.get-started-button-text {
		font: 700 3.495vw "Plus Jakarta Sans";
	}

	.see-usecases-button-text {
		font: 600 3.495vw "Plus Jakarta Sans";
	}
}

@media (min-width: 540px) {
	.hero-title-text-and-description {
		gap: 1.823vh;
	}

	.hero-content {
		width: 90%;
		padding: 2px;
	}

	.hero-title {
		font: 600 50px "Plus Jakarta Sans";
	}

	.second-section-title {
		font: 600 40px "Plus Jakarta Sans";
	}
	.hero-description {
		font: 400 17px/28px "Plus Jakarta Sans";
		width: 80%;
	}

	.get-started-button {
		padding: 12.57px 22px;
		height: 4vh;
	}
	.see-usecase-button {
		padding: 12.57px 22px;
	}

	.get-started-button-text {
		font: 700 14.96px "Plus Jakarta Sans";
	}

	.see-usecases-button-text {
		font: 600 13.21px "Plus Jakarta Sans";
	}
}

@media (min-width: 1024px) {
	.hero-title-text-and-description {
		gap: 1.823vh;
	}

	.hero-title {
		font: 600 5vw "Plus Jakarta Sans";
	}

	.second-section-title {
		font: 600 3.8vw "Plus Jakarta Sans";
	}
	.hero-description {
		font: 400 1.145vw/2vw "Plus Jakarta Sans";
		width: 38.672vw;
	}
	.hero-content {
		width: 55.599vw;
	}
	.get-started-button {
		padding: 1.14vh 1.417vw;
		height: 4vmax;
	}
	.see-usecase-button {
		padding: 1.14vh 1.417vw;
	}

	.get-started-button-text {
		font: 700 1.121vw "Plus Jakarta Sans";
	}

	.see-usecases-button-text {
		font: 600 0.99vw "Plus Jakarta Sans";
	}
}

.easy-and-beautiful-section,
.easy-and-beautiful-section * {
	box-sizing: border-box;
}

.easy-and-beautiful-section {
	display: flex;
	flex-direction: column;
	gap: 44px;
	width: 100%;
	margin-top: 22vh;
	margin-bottom: 8vh;
	align-items: center;
	justify-content: flex-start;
	position: relative;
}

.second-section-title {
	color: #ffffff;
	text-align: left;
	font: 600 60.72px/90.79px "Plus Jakarta Sans";
	position: relative;
}

.second-section-image {
	border-width: 9.15px;
	flex-shrink: 0;
	width: 80%;
	height: auto;
	position: relative;
}

.whysection,
.whysection * {
	box-sizing: border-box;
}

.whysection {
	display: block;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	flex-shrink: 0;
	position: relative;
	margin-top: 4vh;
	margin-bottom: 12vh;
	width: 100%;
	padding: 5%;
}

.why-text {
	color: #ffffff;
	text-align: left;
	font: 600 28.24px/50px "Plus Jakarta Sans";
	position: relative;
}

.why-text-span {
	color: #ffffff;
	text-decoration: underline;
}

.dm-image {
	padding: 10px 10px 10px 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: auto;
	max-width: 100%;
	height: auto;
	position: relative;
}

@media (min-width: 280px) {
	.why-text {
		font: 600 6.099vw/10.138vw "Plus Jakarta Sans";
	}
}

@media (min-width: 1024px) {
	.whysection {
		display: flex;
	}

	.why-text {
		font: 600 2.099vw/3.138vw "Plus Jakarta Sans";
	}

	.dm-image {
		width: 40%;
	}
}

.image-12 {
	flex-shrink: 0;
	width: 378px;
	height: 334.62px;
	position: relative;
}

.footer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 12.5vh;
	border: solid #2e2d2d;
	border-width: 1px 0px 0px 0px;
	bottom: 0;
}

.footer-hackathon-text {
	font: 400 17px/28px "Plus Jakarta Sans";
	letter-spacing: 0.5px;
	color: rgb(190, 190, 190);
}
